
Event = {
    id: Number,
    eventName: String,
    desription: String,
    startTime: String,
    endTime: String,
    address: String,
    ticketLink: String,
    city: String,
    date: String,
    isEventActive: Boolean
};

const eventCities = [
    {
        id: 1,
        cityName: "Hyderabad"
    },
    {
        id: 2,
        cityName: "Bangalore"
    },
    {
        id: 3,
        cityName: "Delhi"
    }
];

const eventsData = [
  {
    id: 1,
    eventName: "Splash your Canvas - Painting Date for Everyone",
    desription:
      "Put on your painter hats, grab your brush and let your creative imagination bring out the best on our canvases. And don't while, we will take care of your hunger too while you are busy at work.",
    startTime:"4:00PM",
    endTime: "7:00PM",
    address: "Good Vibes Only Cafe, Khajaguda",
    ticketLink: "https://rzp.io/l/BhzJRXpRQ",
    city: "Hyderabad",
    date: "22 June 2024",
    isEventActive: true
  },
  {
    id: 2,
    eventName: "Blind Dating",
    desription:
      "Sorry, we are busy organising some other events right now. But will get back with a detailed description soon.",
    startTime:"",
    endTime: "",
    address: "To be declared",
    ticketLink: "",
    city: "Hyderabad",
    date: "We'll let you know beforehand",
    isEventActive: false
  }
];
export { eventsData, eventCities };
