const LandingBanner = () => {
    return (
        <section id="parallax" class="slider-area slider-bg second-slider-bg d-flex align-items-center justify-content-center fix" style={{ backgroundImage: 'url(img/header1_bg_img.jpg)' }}>
        <div class="slider-shape ss-one layer" data-depth="0.10"><img src="img/shape/slider_shape01.png" alt="shape" /></div>
        <div class="slider-shape ss-two layer" data-depth="0.30"><img src="img/shape/slider_shape02.png" alt="shape" /></div>
        <div class="slider-shape ss-three layer" data-depth="0.40"><img src="img/shape/slider_shape03.png" alt="shape" /></div>
        <div class="slider-shape ss-four layer" data-depth="0.60"><img src="img/shape/slider_shape04.png" alt="shape" /></div>
        <div class="slider-shape ss-five layer" data-depth="0.20"><img src="img/shape/slider_shape05.png" alt="shape" /></div>
        <div class="slider-shape ss-six layer" data-depth="0.15"><img src="img/shape/slider_shape06.png" alt="shape" /></div>
        {/* <!-- <div class="slider-shape ss-eight layer" data-depth="0.50"><img src="img/man_header.png" alt="shape"></div> --> */}
        <div class="slider-active">
          <div class="single-slider">
            <div class="container">
              <div class="row">
                <div className="col-1">
                </div>
                <div class="col-10">
                  <div class="slider-content second-slider-content"  style={{
                      textAlign: 'center'
                    }} >
                    <img src="img/logo/sflogo.png" alt="logo" width="40%" style={{marginBottom: "50px"}} className="bannerlogo" />
                    <ul data-animation="fadeInUp animated" data-delay=".2s">
                      {/* <!-- <li><i class="fas fa-map-marker-alt"></i> Waterfront Hotel, London</li> --> */}
                      {/* <!-- <li><i class="far fa-clock"></i>  5 - 7 June 2019, </li> --> */}
                    </ul>
                    <h2 data-animation="fadeInUp animated" data-delay=".4s" style={{
                      textAlign: 'center'
                    }}>Tap into the World of  <span><br />FUN WEEKENDS</span></h2>
                    {/* <div countdown class="conterdown wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s" data-date="Jan 1 2022 00:00:00">
                      <div class="timer">
                        <div class="timer-outer bdr1">
                          <span class="days" data-days>0</span>
                          <div class="smalltext">Days</div>
                          <div class="value-bar"></div>
                        </div>
                        <div class="timer-outer bdr2">
                          <span class="hours" data-hours>0</span>
                          <div class="smalltext">Hours</div>
                        </div>
                        <div class="timer-outer bdr3">
                          <span class="minutes" data-minutes>0</span>
                          <div class="smalltext">Minutes</div>
                        </div>
                        <div class="timer-outer bdr4">
                          <span class="seconds" data-seconds>0</span>
                          <div class="smalltext">Seconds</div>
                        </div>
                        <p id="time-up"></p>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-1">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  export default LandingBanner;