const JoinUs = () => {
    return (
        <section id="pricing" class="pricing-area pt-113 pb-90 fix" style={{ backgroundImage: "url(img/pricing_bg.jpg);background-size: cover;" }}>
        <div class="section-t team-t paroller" data-paroller-factor="0.15" data-paroller-factor-lg="0.15"
          data-paroller-factor-md="0.15" data-paroller-factor-sm="0.15" data-paroller-type="foreground"
          data-paroller-direction="horizontal">
          <h2>Join Us</h2>
        </div>
        <div class="row justify-content-center">
          <div class="col-xl-6 col-lg-8">
            <div class="section-title text-center mb-80">
              <span class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Join Us</span>
              <h2 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">Avengers Assemble</h2>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="pricing-box active text-center mb-30 wow fadeInDown animated" data-animation="fadeInDown animated"
                data-delay=".2s">
                <div class="pricing-head">
                  <h4>Become an Event Director</h4>
                </div>
                <div class="pricing-body mb-40">
                  <p>Well, we did try covering a lot of clubs, but if we are missing something -would you want to take up running a club? It's really simple, just fill the form and our team will get back to you in a jiffy</p>
                 <div class="pricing-btn mt-40">
                    <a href="#" class="btn"><i class="far fa-ticket-alt"></i>Join Us</a>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-lg-6 col-md-6">
            <div class="pricing-box active text-center mb-30 wow fadeInDown animated" data-animation="fadeInDown animated"
                data-delay=".2s">
                <div class="pricing-head">
                  <h4>Collaborate with Us</h4>
                </div>
                <div class="pricing-body mb-40">
                  <p>Own a brand or have a venue that you'd want to associated with Communitie? Reach out to us, BYOP (Bring Your Own Place) and we'll bring the vibe</p>
                  <div class="pricing-btn mt-40">
                    <a href="#" class="btn"><i class="far fa-ticket-alt"></i>Join Us</a>
                  </div>
                </div>

              </div>
            </div>
            {/* <div class="col-lg-4 col-md-6">
            <div class="pricing-box active text-center mb-30 wow fadeInDown animated" data-animation="fadeInDown animated"
                data-delay=".2s">
                <div class="pricing-head">
                  <h4>Leader Work Name</h4>
                </div>
                <div class="pricing-body mb-40">
                  <p>"Work Description.Work Description.Work Description.Work Description.Work Description.Work Description.</p>
                  <strong>Some text</strong>
                    <div class="pricing-btn mt-40">
                        <a href="#" class="btn"><i class="far fa-ticket-alt"></i>Join Us</a>
                    </div>
                </div>

              </div>
            </div> */}
          </div>
        </div>
      </section>
    );
  };
  
  export default JoinUs;