const Socials = () => {
    return (
        <section class="sponsors services-bg pt-113 fix">
        <div class="container">
          <div class="section-t team-t paroller" data-paroller-factor="0.15" data-paroller-factor-lg="0.15"
            data-paroller-factor-md="0.15" data-paroller-factor-sm="0.15" data-paroller-type="foreground"
            data-paroller-direction="horizontal">
            <h2>Our Socials</h2>
          </div>
          <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-8">
              <div class="section-title mb-40">
                <span class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Our Socials</span>
                <h2 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Follow us on our Socials</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 text-center">
              <ul class="wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
                <li><a href="https://www.instagram.com/social.flock/"><img src="img/iglogo.png" alt="img" style={{
                  height:'11vh'
                }}/></a></li>
                <li><a href="https://www.facebook.com/profile.php?id=61560768913665"><img src="img/FacebookLogo.png" alt="img" style={{
                  height:'14vh'
                }}/></a></li>
                <li><a href="https://www.linkedin.com/company/socialflock"><img src="img/linkedin.png" alt="img" style={{
                  height:'7vh'
                }}/></a></li>
                <li><a href="https://x.com/Social__Flock"><img src="img/x.png" alt="img" style={{
                  height:'7vh'
                }}/></a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  export default Socials;