const Stats = () => {
    return (
        <section>
        <div class="counter-area pt-120 pb-120" style={{ backgroundImage: 'url(img/counter_bg.jpg);background-size: cover;', textAlign:'center' }}>
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-sm-6">
              <div class="about-title second-atitle">
                <h2 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Join us at our next event</h2>
                <h5 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">We promise to make your weekends fun and productive.</h5>
              </div>
              <ul class="wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
                <li>
                  <img src="img/calendar.png" alt="img" />
                  <span>Mark your calendars for our events, drag your friends out of their homes and buy the tickets now for a weekend full of fun, bonding and socialising.</span>
                </li>
              </ul>
            </div>
            <div class="col-lg-6 col-sm-6">
              <div class="single-counter text-center mb-30 cr1">
                <div class="counter p-relative">
                  <span class="count">5</span>
                  <small>+</small>
                </div>
                <p>Events</p>
              </div>
              <div class="single-counter text-center mb-30 cr2">
                <div class="counter p-relative">
                  <span class="count">50</span>
                  <small>+</small>
                </div>
                <p>Members added to <br />the community</p>
              </div>
              <div class="single-counter text-center mb-30 cr3">
                <div class="counter p-relative">
                  <span class="count">3</span>
                </div>
                <p>Cities</p>
              </div>
              <div class="cr4"></div>
              <div class="cr5"></div>
              <div class="cr6"></div>

            </div>
          </div>
        </div>
      </div>
      </section>
    );
  };
  
  export default Stats;