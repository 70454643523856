import { eventCities, eventsData } from "./EventsData";
import { useState } from "react";

const Events = () => {

  const [isActiveCity, setIsActiveCity] = useState("Hyderabad");

    return (
        <section>
              <div class="event fix pt-120 pb-120">
        <div class="section-t team-t paroller" data-paroller-factor="0.15" data-paroller-factor-lg="0.15"
          data-paroller-factor-md="0.15" data-paroller-factor-sm="0.15" data-paroller-type="foreground"
          data-paroller-direction="horizontal">
          <h2>Event</h2>
        </div>
        <div class="row justify-content-center">
          <div class="col-xl-6 col-lg-8">
            <div class="section-title text-center mb-80">
              <span class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Events</span>
              <h2 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Upcoming Events</h2>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-12 ">
              <nav class="wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
                <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                  {
                    eventCities.map((city) => (
                      <a key={city.id} className={`${
                        (isActiveCity == city.cityName) ? "active" : ""} nav-item nav-link show
                      `} id="nav-home-tab" data-toggle="tab" role="tab"
                      aria-selected="true" onClick={() => setIsActiveCity(city.cityName)}>
                      <img src="img/t-icon.png" alt="img" class="drk-icon" />
                      <img src="img/t-w-icon1.png" alt="img" class="lgt-icon" />
                      <div class="nav-content">
                        <strong>{}</strong>
                        <strong>{city.cityName}</strong>
                      </div>
                      </a>
                    ))
                  }
                </div>
              </nav>
              <div class="tab-content py-3 px-3 px-sm-0 wow fadeInDown animated" data-animation="fadeInDown animated"
                data-delay=".2s" id="nav-tabContent">
                <div class="tab-pane fade active show" id="one" role="tabpanel" aria-labelledby="nav-home-tab">
                  {/* <!-- row loop --> */}
                  {
                    eventsData.map((event) => (
                      <div key={event.id}>
                        {
                          (isActiveCity == event.city) ?
                          <div class="row mb-30" >
                          <div class="col-lg-12">
                            <div class="event-list-content fix">
                              <ul data-animation="fadeInUp animated" data-delay=".2s" style={{ animationDelay: '0.2s;' }}>
                                <li><i class="fas fa-map-marker-alt"></i> {event.address}</li>
                                <li><i class="far fa-clock"></i> {event.startTime ? `${event.startTime} -` : "Yet to decide"} {event.endTime}</li>
                                <li><i class="far fa-calendar"></i> {event.date}</li>
                              </ul>
                              <h2>{event.eventName}</h2>
                              <p>{event.desription}</p>
                              <a href={event.ticketLink} className={`${event.isEventActive ? "btn" : "btn disabled-btn"} mt-20 mr-10`}><i class="far fa-ticket-alt"></i> {event.isEventActive ? "Buy Tickets" : "Coming Soon"}</a>
                              <div class="crical"><i class="fal fa-video"></i> </div>
                            </div>
                          </div>
                        </div> :
                        <div> </div>
                        }
                    </div>
                    ))
                  }
                  
                    <div>
                    {(eventsData.filter((event) => event.city == isActiveCity).length == 0) ?
                    <div class="row mb-30" >
                          <div class="col-lg-12">
                            <div class="event-list-content fix">
                              {/*<ul data-animation="fadeInUp animated" data-delay=".2s" style={{ animationDelay: '0.2s;' }}>
                                <li><i class="fas fa-map-marker-alt"></i> {event.address}</li>
                                <li><i class="far fa-clock"></i> {event.startTime} - {event.endTime}</li>
                                <li><i class="far fa-calendar"></i> {event.date}</li>
                              </ul> */}
                              <h2>Coming Soon to {isActiveCity}</h2>
                              <p>Don't worry we will be there in your city, {isActiveCity}, in no time.<br /> Just figuring out the logistics and winning a few hearts before our next event there.</p>
                              {/* <a href={event.ticketLink} class="btn mt-20 mr-10"><i class="far fa-ticket-alt"></i> Buy Tickets</a> */}
                              <div class="crical"><i class="fal fa-video"></i> </div>
                            </div>
                          </div>
                          </div>
                          :
                          <div></div>}
                    </div>

                </div>
              </div>
            </div>
            {/* <div class="col-lg-12 justify-content-center text-center">
              <a href="#" class="btn mt-20 mr-10">Check out other Events</a>
            </div> */}
          </div>
        </div>
      </div>
      </section>
    );
  };
  
  export default Events;