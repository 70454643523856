import logo from './logo.svg';
import './App.css';
import IntroVideo from './components/IntroVideo';
import Stats from './components/Stats';
import JoinUs from './components/JoinUs';
import Socials from './components/Socials';
import LandingBanner from './components/LandingBanner';
import Events from './components/Events/Events';

function App() {
  return (
    <main>
      <LandingBanner />
      <IntroVideo />
      <Stats />
      <Events />
      <JoinUs />
      <Socials />
    </main>
  );
}

export default App;
