const IntroVideo = () => {
    return (
        <section class="sponsors services-bg pt-80 pb-120 fix">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="s-video-wrap wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s"
                style={{ backgroundImage: "url(img/video_bg.png)" }}>
                <div class="s-video-content mb-80">
                  <a href="#" class="popup-video mb-50"><i
                    class="fas fa-play"></i></a>
                  <h2>Take a look into our events</h2>
                  <p>Bringing to you a small clip about the events we wish to bring to your city.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  export default IntroVideo;